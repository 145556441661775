<template>
  <main>
    <div class="preparebox">
      <div class="prepare_title">
        手动报修
      </div>
      <van-form @submit="onSubmit" ref="formRef">
        <div class="cell">

          <div class="col-2">
            <div class="item required">
              <div class="label">手机号:</div>
              <div class="input">
                <van-field
                    placeholder="请输入您的手机号"
                    name="clientCellphone"
                    required
                    v-model.trim="form.clientCellphone"
                    :rules="[{ required: true, message: '请填写手机号' }]"/>
              </div>
            </div>
            <div class="item required">
              <div class="label">姓名:</div>
              <div class="input">
                <van-field
                    placeholder="请输入您的姓名"
                    name="clientName"
                    required
                    v-model.trim="form.clientName"
                    :rules="[{ required: true, message: '请填写姓名' }]"/>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div class="item required">
              <div class="label">地址:</div>
              <div class="input">
                <van-field
                    placeholder="工程师将根据此地址上门服务"
                    name="clientAddress"
                    required
                    v-model.trim="form.clientAddress"
                    :rules="[{ required: true, message: '请填写详细地址' }]"/>
              </div>
            </div>
            <div class="item required">
              <div class="label">预约时间:</div>
              <div class="input">
                <van-field
                    placeholder="请选择预约时间"
                    name="appointmentTime"
                    readonly
                    required
                    clickable
                    @click="showPicker = true"
                    v-model="form.appointmentTime"
                    :rules="[{ required: true, message: '请选择预约上门时间' }]"/>
              </div>
            </div>
          </div>

          <div class="item required">
            <div class="label">问题描述:</div>
            <div class="input">
              <van-field
                         placeholder="请填写问题描述"
                         class="description"
                         v-model.trim="form.description"
                         required
                         autosize
                         clearable
                         show-word-limit
                         type="textarea"
                         maxlength="128"
                         name="description"
                         :rules="[{ required: true, message: '请填写问题描述' }]"/>
            </div>
          </div>
          <div class="item">
            <div class="label">文件上传:</div>
            <div class="input">
              <van-field name="files" >
                <template #input>
                  <van-uploader multiple
                                v-model="form.files"
                                :max-count="3"
                                :max-size="1024 * 10 * 1024"
                                @oversize="onOversize"/>

                </template>
              </van-field>
            </div>
          </div>

          <div class="col-2">
            <div class="item required">
              <div class="label">验证码:</div>
              <div class="input">
                <van-row>
                  <van-col span="16">
                    <van-field
                        placeholder="请输入验证码"
                        :left-icon="require('../../../asset/form/captcha.png')"
                        name="captcha"
                        required
                        clearable
                        v-model.trim="form.captcha"
                        :rules="[{ validator: validationCaptcha, message: '请输入正确内容' }]"/>
                  </van-col>
                  <van-col span="8">
                    <van-image width="108" height="40" :src="captchaUrl" @click="getCaptcha"/>
                  </van-col>
                </van-row>

              </div>
            </div>
          </div>
        </div>


          <van-popup v-model:show="showPicker" position="bottom">
            <van-datetime-picker type="datetime"
                                 :min-date="new Date()"
                                 @confirm="onConfirm"
                                 @cancel="showPicker = false"/>
          </van-popup>
            <van-button round type="primary" native-type="submit" class="submit" :loading="loading">提交
            </van-button>


      </van-form>
    </div>
  </main>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {Dialog, Toast} from "vant";
import {useRouter} from "vue-router";
import dd from "gdt-jsapi"//专有钉钉
import axios from "@/plugin/axios";
import {getUrlParams} from "../../../plugin/tools";
import {userInfo} from "../../../api/ding";

onMounted(() => {
  validationUrlParam();
  getDingUserInfo();//获取钉钉用户信息
  form.clientCellphone = localStorage.getItem("createWorkorderPhone")
  form.clientName = localStorage.getItem("createWorkorderName")
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth().toString().padStart(2,0);
  let d = date.getDate().toString().padStart(2,0);
  let hour = date.getHours().toString().padStart(2,0);
  let m = date.getMinutes().toString().padStart(2,0)
  //form.appointmentTime = year+"-"+month+ "-" + d + " " +hour+":"+m;
  getCaptcha();
});

const router = useRouter();
const formRef = ref(null);

const captchaUrl = ref("");
// 验证码错误信息提示
const errorMessage = ref("");
// 时间选择器
let showPicker = ref(false);
// 控制验证码的弹窗
const show = ref(false);

const loading = ref(false);

const query = getUrlParam();
const queryParams = getUrlParams();
// 表单
const form = reactive({
  clientCellphone: "",
  clientName: "",
  clientAddress: "",
  appointmentTime: "",
  description: "",
  captchaId: "",
  captcha: "",
  files: []
});

const onConfirm = value => {
  const dateTime = new Date(value);
  let year = dateTime.getFullYear();
  let month = (dateTime.getMonth() + 1).toString().padStart(2, 0);
  let day = dateTime.getDate().toString().padStart(2, 0);
  let hour = dateTime.getHours().toString().padStart(2, 0);
  let minute = dateTime.getMinutes().toString().padStart(2, 0);

  form.appointmentTime = `${year}-${month}-${day} ${hour}:${minute}`;
  showPicker.value = false;
};

/**
 * 限制上传图片的大小
 */
const onOversize = () => {
  Toast("文件大小不能超过 10M");
};

/**
 * 刷新验证码
 */
const getCaptcha = () => {
  axios.get("/workorder/workorder/prepare/captcha").then(res => {
    captchaUrl.value = res.data.base64Image;
    form.captchaId = res.data.captchaId;
    errorMessage.value = "";
  }).catch(error => {
    console.warn("Get workorder prepare captcha fail", error.response);
  });
};
/**
 *  获取钉钉用户信息
 */
const getDingUserInfo = () => {
  userInfo({authCode: queryParams.auth_code}).then(res => {
    let {account, lastName} = res.data;
    form.clientCellphone = account!==null?account:localStorage.getItem("createWorkorderPhone");
    form.clientName = lastName!==null?lastName:localStorage.getItem("createWorkorderName");
   if (account){
     localStorage.setItem("createWorkorderPhone",account)
     localStorage.setItem("createWorkorderName",lastName)
   }
    console.log(res)
  }).catch(error => {
    console.warn("Get ding user info fail", error.response);
  });
};
/**
 * 校验验证码
 * @param value 验证码
 */
const validationCaptcha = value => {
  if (!!value) {
    if (!!errorMessage.value) {
      return errorMessage.value;
    } else {
      return true;
    }
  } else {
    return "请输入验证码";
  }
};

/**
 * 提交表单
 */
const onSubmit = () => {
  localStorage.setItem("createWorkorderPhone",form.clientCellphone)
  localStorage.setItem("createWorkorderName",form.clientName);
  let formData = new FormData();
  formData.append("clientCellphone", form.clientCellphone);
  formData.append("clientName", form.clientName);
  formData.append("clientAddress", form.clientAddress);
  formData.append("description", form.description);
  formData.append("appointmentTime", form.appointmentTime);
  formData.append("captcha", form.captcha);
  formData.append("captchaId", form.captchaId);
  !!query.origin ? formData.append("origin", query.origin) : formData.append("origin", "prepare");
  if (form.files.length > 0) {
    form.files.forEach(item => {
      formData.append("files", item.file);
    });
  }
  formData.append("headers", {"Content-Type": "multipart/form-data"});
  loading.value = true;
  axios.post("/workorder/workorder/prepare", formData).then(response => {
    loading.value = false;
    Dialog.alert({
      title: "提交成功",
      confirmButtonText: "返回首页"
    }).then(() => {
      router.push({path: "/", query: getUrlParam()});
    });
  }).catch(error => {
    loading.value = false;
    if (error.status === 40000 || error.status === 40400) {
      errorMessage.value = error.data;
      formRef.value.validate("captcha");
      getCaptcha();
    }
  });
};

</script>

<style scoped>
.preparebox {
  width: 80%;
  margin: 0 auto;
}
.prepare_title{
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  line-height: 160px;
  height: 100px;
}

.cell{
margin-bottom: 30px;
}
.col-2{
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.item {
  display: flex;
  flex-direction: column;
}

.item .label {
height: 50px;
  line-height: 50px;
}
.required .label:before{
  content: '*';
  color: red;
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(-1px);
  font-weight: bold;
}

.item .input {
  border: 1px #ddd solid;
  border-radius: 5px;
  overflow: hidden;
}
.col-2 .item{width: calc(50% - 10px);}
.submit{width: 150px; margin: 0 auto;display: block;}
.nav-left-img {
  width: 30px;
}

/deep/ .van-nav-bar__title {
  color: white;
}

/deep/ .van-field__left-icon {
  display: flex;
  align-items: center;
}

/deep/ .van-icon__image {
  width: 1.3em;
  height: 1.3em;
}

.description /deep/ .van-field__left-icon {
  align-items: start;
}

/deep/ .van-nav-bar--fixed {
  background-color: #0546EF;
}

.van-hairline--bottom:after {
  border-bottom-width: 0;
}


/deep/ .van-cell-group--inset {
  margin: 20px 0 0 0;
  padding-top: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}
</style>
